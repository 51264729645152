<template>
<div class="page-banner">
  <div class="container">
    <div class="banner-text">{{text}}</div>
  </div>
</div>
</template>

<script>
export default {
  name: "CommBanner",
  props:{
    text:{
      type:String,
      default:""
    }
  }
}
</script>

<style lang="scss" scoped>
.page-banner{
  height: 600px;
  background: url("./images/banner.png") no-repeat;
  background-size: auto 100%;

  .container {
    height: 100%;
    display: flex;
    align-items: center;

    .banner-text {
      padding-left: 32px;
      color: #231916;
      font-size: 80px;
    }
  }
}
</style>
