<template>
  <div class="page-team">
    <comm-banner :text="$t('menu.team')" />
    <div class="container">
      <div class="team-box">
        <!--        <h4 class="comm-title">团队</h4>-->
        <ul class="team-list" :class="{shader:activeIndex!==-1}">
          <li v-for="(item,index) in teamList" :key="index"
              :class="{active:activeIndex===index}">
            <div class="item" @mouseleave="handleMouseLeave" @mouseenter="handleMouseEnter(index)">
              <div class="img-wrap">
                <img :src="require(`./images/${item.img}.png`)" alt="">
              </div>
              <div class="text-wrap">
                <div class="name"><i class="icon-round"/>{{ item.name }}</div>
                <div class="title">{{ item.title }}</div>
                <div class="territory">{{ item.territory }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CommBanner from "@/components/CommBanner";

export default {
  name: "Team",
  components: {CommBanner},
  data() {
    return {
      activeIndex: -1,
      teams: [
        {
          "name": "周畅 Clara",
          "title": "董事总经理",
          "territory": "专注科技领域投资",
          "img": "pic-ClaraZhou"
        },
        {
          "name": "唐正 Don",
          "title": "管理合伙人",
          "territory": "",
          "img": "pic-DonTang"
        },
        {
          "name": "张友芳 Fanny",
          "title": "运营合伙人",
          "territory": "",
          "img": "pic-FannyZhang"
        },
        {
          "name": "周海文 Haiwen",
          "title": "合伙人",
          "territory": "专注消费领域投资",
          "img": "pic-HaiwenZhou"
        },
        {
          "name": "赵轶越 Lindsay",
          "title": "投资经理",
          "territory": "专注科技领域投资",
          "img": "pic-LindsayZhao"
        },
        {
          "name": "苏金明 Quintus",
          "title": "董事总经理",
          "territory": "专注消费领域投资",
          "img": "pic-QuintusSu"
        },
        {
          "name": "李侃 Ronnie",
          "title": "管理合伙人",
          "territory": "",
          "img": "pic-RonnieLi"
        },
        {
          "name": "WF Chen",
          "title": "管理合伙人",
          "territory": "",
          "img": "pic-WanfengChen"
        }
      ],
      teamsEng: [
        {
          "name": "Clara Zhou",
          "title": "Managing Director",
          "territory": "Technology Investment",
          "img": "pic-ClaraZhou"
        },
        {
          "name": "Don Tang",
          "title": "Managing Partner",
          "territory": "",
          "img": "pic-DonTang"
        },
        {
          "name": "Fanny Zhang",
          "title": "Operating Partner",
          "territory": "",
          "img": "pic-FannyZhang"
        },
        {
          "name": "Haiwen Zhou",
          "title": "Partner",
          "territory": "Consumer Investment",
          "img": "pic-HaiwenZhou"
        },
        {
          "name": "Lindsay Zhao",
          "title": "Investment Manager",
          "territory": "Technology Investment",
          "img": "pic-LindsayZhao"
        },
        {
          "name": "Quintus Su",
          "title": "Managing Director",
          "territory": "Consumer Investment",
          "img": "pic-QuintusSu"
        },
        {
          "name": "Ronnie Li",
          "title": "Managing Partner",
          "territory": "",
          "img": "pic-RonnieLi"
        },
        {
          "name": "WF Chen",
          "title": "Managing Partner",
          "territory": "",
          "img": "pic-WanfengChen"
        }
      ]
    }
  },
  computed: {
    ...mapState(['lang']),
    teamList() {
      if (this.lang === 'zh') {
        return this.teams
      }
      return this.teamsEng
    }
  },
  methods: {
    handleMouseEnter(index) {
      this.activeIndex = index;
    },
    handleMouseLeave() {
      this.activeIndex = -1;
    }
  }
}
</script>

<style lang="scss" scoped>
.page-team {
  .page-team-banner {
    height: 600px;
    background: url("./images/team-banner.png") no-repeat center;
    background-size: auto 100%;
  }

  .team-box {
    padding-top: 140px;
    overflow: hidden;
  }

  .team-list {
    display: flex;
    padding-top: 24px;
    margin-left: -17px;
    margin-right: -17px;
    flex-wrap: wrap;

    > li {
      width: 25%;

      .item {
        position: relative;
        margin-left: 17px;
        margin-right: 17px;
        margin-bottom: 147px;

        .img-wrap {
          position: relative;
          width: 325px;
          height: 500px;
          border-radius: 8px;
          overflow: hidden;

          img {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
          }

          &:after {
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            content: "";
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
            border-radius: 8px;
            opacity: 0;
            transition: all 0.3s ease-in;
            z-index: 2;
          }
        }

        .text-wrap {
          position: absolute;
          left: 0;
          top: 100%;
          padding-top: 12px;
          opacity: 0;
          transition: all 0.3s ease-in;

          .name {
            display: flex;
            align-items: center;
            font-size: 24px;
            line-height: 33px;
            font-weight: 600;
            margin-bottom: 10px;

            .icon-round {
              display: block;
              margin-right: 6px;
              width: 20px;
              height: 20px;
              background: url("./images/round.png") no-repeat;
              background-size: 100% 100%;
            }
          }

          .title,
          .territory {
            font-size: 24px;
            color: #231916;
            line-height: 30px;
            margin-bottom: 5px;
          }
        }
      }

      &.active {
        .item {
          .img-wrap {
            &:after {
              opacity: 0 !important;
            }
          }

          .text-wrap {
            opacity: 1;
          }
        }
      }
    }

    &.shader {
      > li {
        .item {
          .img-wrap {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
